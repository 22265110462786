<template>
  <div class="container content">
    <div class="row justify-content-center">
      <div class="col">
        <h4>{{ building.building_name }}</h4>
        <p
          v-if="building.portfolio !== null"
          class="subtitle"
        >
          <router-link
            :to="{name: 'portfolio', params: {'id': $route.params.id}}"
          >
            {{ building.portfolio.business_name }}
          </router-link>
        </p>
        <div
          v-else
          class="d-block"
          style="margin-bottom: 39px;"
        />
        <template v-if="internet && building.aruba_user">
          <div
            class="btn btn-primary btn-edit"
            data-target="#connectArubaModal"
            data-toggle="modal"
            style="right: 330px"
          >
            Manage integration
          </div>
        </template>
        <template
          v-if="(internet && !internet_automation) ||
            (internet_automation && building.aruba_user)"
        >
          <div
            class="btn btn-primary btn-edit"
            data-target="#addTier"
            data-toggle="modal"
            style="right: 229px"
          >
            Add tier
          </div>
        </template>
        <template
          v-if="(internet && internet_automation)"
        >
          <div
            :data-target="'#confirm-generate-aruba-template'"
            class="btn btn-primary btn-edit"
            data-toggle="modal"
          >
            Generate Aruba Template
          </div>
        </template>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-12 col-md-auto">
        <Navigation />
      </div>
      <div class="col">
        <h4 class="font-weight-normal">
          Manage WiFi Tiers
        </h4>
        <div
          v-if="!internet"
          class="row justify-content-center"
        >
          <div class="col-10 text-center">
            <h2>📻</h2>
            <p>This building does not have WiFi enabled.</p>
            <small class="d-block">Skip to the next step.</small>
          </div>
        </div>
        <div
          v-else-if="!building.aruba_user && internet_automation"
          class="row justify-content-center"
        >
          <div class="col-12 text-center bg-white py-5 mt-3">
            <h2>🔌</h2>
            <p class="h4 my-3">
              Connect Aruba to begin Wifi Configuration.
            </p>
            <button
              class="btn btn-primary px-3 py-2"
              data-target="#connectArubaModal"
              data-toggle="modal"
            >
              Connect integration
            </button>
          </div>
        </div>
        <div
          v-else-if="building.internet_tiers_count === 0"
          class="row justify-content-center"
        >
          <div class="col-12 text-center bg-white py-5 mt-3">
            <h2>✅</h2>
            <p class="h4 my-3">
              Connected. Add your Wifi tiers.
            </p>
            <button
              class="btn btn-primary px-3 py-2"
              data-target="#addTier"
              data-toggle="modal"
            >
              Add tier
            </button>
          </div>
        </div>
        <div
          v-else
          class="row justify-content-center"
        >
          <div class="col-12">
            <InternetTier
              v-for="tier in building.internet_tiers"
              :key="tier.id"
              :default_id="building.internet_configuration.internet_tier_id"
              :downgrade_id="building.internet_configuration.downgrade_internet_tier_id"
              :images="images"
              :tier="tier"
              @updated="getBuilding"
            />
          </div>
        </div>
      </div>
    </div>
    <footer class="building-footer">
      <div class="container">
        <div class="row justify-content-end">
          <div class="col col-md-6 col-lg-3">
            <router-link
              :to="{name: 'brivosite', params: {'id': $route.params.id, 'building_id': $route.params.building_id}}"
              class="btn btn-primary btn-block"
              tag="button"
            >
              Next
            </router-link>
          </div>
        </div>
      </div>
    </footer>
    <AddWiFiTierModal
      :images="images"
      @updated="getBuilding"
    />
    <ConnectArubaModal
      :aruba_user="building.aruba_user"
      @updated="getBuilding"
    />
    <ConfirmModal
      :id="'confirm-generate-aruba-template'"
      :message="'Generate and download Aruba Template?'"
      :title="'Generate Aruba Template'"
      @confirmed="generateArubaTemplate"
    />
  </div>
</template>

<script>
import api from '../../../api.js';
import Navigation from '../../../components/BuildingNav.vue';
import InternetTier from '../../../components/InternetTier.vue';
import AddWiFiTierModal from '../../../components/modals/AddWiFiTierModal.vue';
import ConnectArubaModal from '../../../components/modals/ConnectArubaModal.vue';
import ConfirmModal from '../../../components/modals/ConfirmModal';

export default {
  name: 'WiFi',
  components: {
    ConfirmModal,
    Navigation,
    InternetTier,
    AddWiFiTierModal,
    ConnectArubaModal,
  },
  data() {
    return {
      building: {
        building_name: '',
        new_features: [],
        internet_tiers: [],
        internet_tiers_count: 0,
        portfolio: {
          business_name: '',
        },
      },
      images: [],
      disabled: false,
      internet: false,
      internet_automation: false,
      confirm_modal_info: {},
    };
  },
  beforeMount() {
    $('.loading').css('display', 'block');
    if (this.$route.params.building_id === 'undefined') {
      this.$router.go(-1);
      this.handleError('You must create a building first!');
    }

    this.getBuilding();
    api.getInternetTierImages()
        .then((response) => {
          this.images = response.internet_tier_images;
        })
        .catch((error) => {
          this.handleError(error);
        });
  },
  methods: {
    getBuilding() {
      api.getBuilding(this.$route.params.building_id)
          .then((response) => {
            this.building = response.building;

            if (this.building.new_features
                .some(
                    (e) => e.identifier === 'internet-automation',
                )
            ) {
              this.internet_automation = true;
            }

            if (this.building.new_features
                .some(
                    (e) => e.identifier === 'internet',
                )
            ) {
              this.internet = true;
            }

            if (this.building.internet_configuration === null) {
              this.disabled = true;
            }

            if ($('.content').css('display') !== 'block') {
              $('.content').css('display', 'block');
              $('.content').animateCss('fadeIn');
            }
          })
          .catch((error) => {
            this.handleError(error);
          });
    },

    getFileName(contentDisposition) {
      if (!contentDisposition) {
        return null;
      }
      const fileNameMatch = contentDisposition
          .match(/filename="(.+)"/);

      if (fileNameMatch.length > 0 && fileNameMatch.length !== 2) {
        return null;
      }

      return fileNameMatch[1];
    },

    generateArubaTemplate() {
      if (this.disabled) {
        return;
      }
      this.disabled = true;
      api.postGenerateArubaTemplate(this.$route.params.building_id)
          .then((response) => {
            const responseData = [JSON.stringify(response.data, null, 2)];
            const blob = new Blob(responseData, {type: response.data.type});
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;

            const fileName = this
                .getFileName(response.headers['content-disposition']);

            if (!fileName) {
              this.disabled = false;
              return;
            }
            // force download
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);
            this.handleSuccess('Successfully generated aruba template');
            this.disabled = false;
          })
          .catch((error) => {
            this.handleError(error);
          });
    },
  },
};
</script>
