<template>
  <div class="row">
    <div class="col">
      <div
        :data-target="'#tier'+tier.id"
        class="card card-link"
        data-toggle="modal"
      >
        <div class="card-body">
          <div class="row">
            <div class="col-2 col-sm-1">
              <img
                :src="tier.image_url"
                class="tier-image"
              >
            </div>
            <div class="col-10 col-lg-3 font-weight-bold">
              {{ tier.name }}
              <small
                v-if="default_id === tier.id"
                class="text-muted pl-1"
              >Default</small>
              <small
                v-if="downgrade_id === tier.id"
                class="text-danger pl-1"
              >Downgrade</small>
            </div>
            <div class="col-4 col-lg-3">
              ↑ {{ tier.up_speed }} Mbps
            </div>
            <div class="col-4 col-lg-3">
              ↓ {{ tier.down_speed }} Mbps
            </div>
            <div class="col-4 col-lg-2">
              ${{ tier.cost / 100 }}
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <hr>
              <span>{{ tier.description }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <EditWiFiTierModal
      :tier="tier"
      :images="images"
      :default_id="default_id"
      :downgrade_id="downgrade_id"
      @updated="$emit('updated')"
    />
  </div>
</template>

<script>
import EditWiFiTierModal from './modals/EditWiFiTierModal.vue';

export default {
  name: 'InternetTier',
  components: {
    EditWiFiTierModal,
  },
  props: {
    tier: Object,
    default_id: String,
    downgrade_id: String,
    images: Array,
  },
};
</script>
